/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, { useState } from 'react';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import { HeroHeading, BodyText } from '../Global';
import Spin from '../Spin';
import theme from '../../styles/theme';
import generalHelper from '../../utils/general';


const PageWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  position: fixed;
  text-align: center;
  top: 23%;
  width: 100%;
  z-index: 101;

  .container {
    padding: 0 30px;
  }

  .logo {
    margin-bottom: 33px;
    img {
      margin-bottom: 0;
      margin-right: 16px;
      vertical-align: middle;
    }
    h3 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }

  p {
    width: 100%;
    max-width: 390px;
    margin: auto;
  }

  .bottom-text {
    width: 100%;
    max-width: 349px;
    margin: auto;
    font-size: 12px;
    line-height: 14.4px;

    a {
      color: ${theme.textColor.blueText};
      text-decoration: none;
      margin: 0 3px;
    }
  }

  .feature {
    margin: 20px auto;
    display: flex;
    align-items: center;
    max-width: 460px;
    text-align: left;
    p {
      max-width: 100%;
      margin-left: 10px;
    }
  }

  form {
    width: 100%;
    max-width: 453px;
    margin: 25px auto;

    input[type="email"] {
      width: 100%;
      margin-bottom: 22px;
      padding: 12px 20px;
      border-radius: 6px;
      border: 1px solid ${theme.borderColor.silver};
      outline: none;
    }
    input[type="checkbox"] {
      margin-right: 7px;
      margin-top: -2px;
      vertical-align: middle;
    }
    label {
      vertical-align: middle;
      font-size: 16px;
      line-height: 1;
    }
  }

  button {
    background: ${theme.hover.hoverBlue};
    color: ${theme.heading.dark};
    border-radius: 6px;
    border: 0px;
    margin: 22px auto;
    min-width: 120px;
    outline: none;
    padding: 10px 24px;
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.hover.hoverLight};
      cursor: pointer;
    }
  }
`;
const BodyTextBold = styled(BodyText)`
  font-weight: bold;
`;


const EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


const GetStartedPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);


  const submitFunction = () => {
    const isValidEmail = EMAIL.test(userEmail);
    if (!isValidEmail) {
      return;
    }

    setLoading(true);
    trackCustomEvent({
      category: 'Get started',
      action: 'Add email',
      label: userEmail,
    });
    const dataLayerPush = {
      event: 'Added email to get started',
      visitorEmail: userEmail,
    };
    if (!generalHelper.isWindowUndefined()) {
      if (window.ga) {
        window.ga((tracker) => {
          dataLayerPush.visitorId = tracker.get('clientId');
        });
      }
      if (window.dataLayer) {
        window.dataLayer.push(dataLayerPush);
      }
    }
  };


  return (
    <PageWrap>
      <div className="container">
        <HeroHeading mb="22px">
          Try Workast for FREE
        </HeroHeading>
        <BodyTextBold mb="22px">
          Start a 14-day trial, no credit card required
        </BodyTextBold>
        <form
          action="https://my.workast.com/login/email"
          method="POST"
        >
          <div>
            <input type="email" name="email" placeholder="Enter your work email" onChange={e => setUserEmail(e.target.value)} required />
          </div>
          <div>
            <button type="submit" onClick={submitFunction}>
              {loading && userEmail ? (<Spin />) : (<>Continue</>)}
            </button>
          </div>
        </form>
      </div>
    </PageWrap>
  );
};

export default GetStartedPage;
