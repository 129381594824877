import React from 'react';
import PropTypes from 'prop-types';
import { CTA } from '../../styles/GlobalStyles';


const CallToAction = ({ text, link, type }) => (
  <CTA
    rel="noopener noreferrer"
    href={link}
    type={type}
  >
    {text}
  </CTA>
);

export default CallToAction;

CallToAction.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  type: PropTypes.string,
};

CallToAction.defaultProps = {
  link: '/get-started/',
  type: '',
};
