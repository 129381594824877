/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Modal from '../Modal/index';
import ModalChild from '../Modal/index.style';
import SignUpForm from './SignUpForm';
import CloseIconPNG from '../../../static/images/close.png';
import { CTAButton } from '../../styles/GlobalStyles';


const ModalChildForSignUp = styled(ModalChild)`
  background: rgba(255, 255, 255, 0.92);
  z-index: 100;
  backdrop-filter: blur(4px);
`;
ModalChildForSignUp.displayName = 'ModalChildForSignUp';

const CloseIcon = styled.img`
  position: fixed;
  right: 18px;
  top: 18px;
  z-index: 101;
  cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const GlobalStyles = createGlobalStyle`
  #fc_frame, #fc_frame.fc-widget-normal {
    z-index: 10;
  }
`;
GlobalStyles.displayName = 'GlobalStyles';


const SignUpModal = (props) => {
  const { buttonText } = props;
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = React.useCallback(
    () => {
      setShowModal(!showModal);
    }
  );


  return (
    <React.Fragment>
      <CTAButton
        type="button"
        onClick={toggleModal}
      >
        {buttonText}
      </CTAButton>
      {showModal && (
        <React.Fragment>
          <GlobalStyles />
          <Modal>
            <CloseIcon
              src={CloseIconPNG}
              alt="Close"
              onClick={toggleModal}
            />
            <SignUpForm />
            <ModalChildForSignUp
              onClick={toggleModal}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SignUpModal.defaultProps = {
  buttonText: 'Try it for free',
};
SignUpModal.propTypes = {
  buttonText: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SignUpModal;
