/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/


class Modal extends PureComponent {
  modalRoot = null;

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    const { container } = this.props;
    this.modalRoot = document.querySelector(container);

    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }

  /*------------------------------------------------*/
  /* RENDER
  /*------------------------------------------------*/
  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(
      children,
      this.el,
    );
  }
}

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
Modal.defaultProps = {
  // Optional properties:
  container: 'body',
  children: null,
};

Modal.propTypes = {
  // Optional props:
  container: PropTypes.string,
  children: PropTypes.node,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default Modal;
