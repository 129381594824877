import styled, { createGlobalStyle } from 'styled-components';
import { math } from 'polished';
import _get from 'lodash/get';
import theme from './theme';

const anchorStyles = `
  background: ${theme.hover.hoverBlue};
  padding: 10px 24px;
  color: ${theme.heading.dark} !important;
  border-radius: 6px;
  text-decoration: none;
  transition: background ease-in-out 0.2s;
  white-space: nowrap;
  display: inline-block;
  line-height: 22px;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.hover.hoverLight};
    cursor: pointer;
    transition: background ease-in-out 0.2s;
  }
`;
const ghostStyles = `
  background: none;
  color: ${theme.hover.hoverBlue} !important;
  border: 1px solid ${theme.hover.hoverBlue};


  &:hover,
  &:active,
  &:focus {
    background: none;
    border-color: ${theme.hover.hoverLight};
    color: ${theme.hover.hoverLight};
  }
`;


const CTA = styled.a`
  ${anchorStyles}

  ${props => _get(props, 'type', '') === 'ghost' && ghostStyles}
`;

const CTAButton = styled.button`
  ${anchorStyles}
`;

const GlobalStyles = createGlobalStyle`
  ${props => props.isEmbededView && `
    html {
      font-size: 12px;
    }
  `}
  
  body {
    color: ${props => theme.body[props.theme]};
    font-size: ${theme.body.fontSize};
    line-height: 1.8rem;
    /* Handles navbar fixed */
    margin-top: 60px;
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;

    ${props => props.isEmbededView && `
      margin-top: 0 !important;
      padding: 0 16px;
    `}
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${props => theme.heading[props.theme]};
    clear: both;
  }
  h1, h2, h3 {
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .no-scroll {
    overflow: hidden;
  }
  .centered-text {
    text-align: center;
  }
  .section {
    padding: 94px 0;
    h1, h2, h3, h4, h5, h6 {
      color: ${theme.textColor.darkGrayText};
    }
  }
  .full-width-dots {
    width: 1600px;
    margin-left: auto;
    margin-right: 0;
    img {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .hover-text {
    text-decoration: none;
    :hover {
      text-decoration: underline;  
    }
  }
  .divider {
    background: ${theme.borderColor.grayBorder};
    width: 100%;
    height: 1px;
    margin: 50px 0;
  }
  .plan-table {
    background: ${theme.table.light};
    tr {
      border: 0px !important;
      th, td {
        text-align: center;
        color: ${theme.textColor.darkGrayText};
        border: 0;
        border-right: 1px solid ${theme.table.dark};
        font-size: 16px;
        line-height: 1.2;
        padding: 21px 0;
        width: 19%;
      }
      th {
        font-weight: bold;
        font-size: 18px;
        background: ${theme.table.light};
        margin-bottom: 20px;
        padding: 21px 0;
      }
      th:nth-child(1) {
        text-align: left;
        max-width: 372px;
        width: 24%;
        padding-left: 42px;
        background: none;
        border-right: 0;
      }
      td {
        padding: 15px 0;
      }
      td:nth-child(1) {
        text-align: left;
        max-width: 372px;
        width: 24%;
        padding-left: 42px;
        border-right: 0;
        font-size: 18px;
      }
    }
    tr:nth-child(2) {
      td {
        padding-top: 40px !important;  
      }
    }
    tr:last-child {
      td {
        padding-bottom: 55px;
      }
    }
  }

  /****************************/
  /******Special content*******/
  /****************************/
  .ContentfulElementButton,
  .ContentfulElementTip,
  .ContentfulElementQuote,
  .ContentfulElementImageColumn {
    margin-bottom: 40px;
  }

  .ContentfulElementButton {
    display: flex;
    justify-content: center;
  
    a {
      ${anchorStyles}
    }
  }

  .ContentfulElementTip {
    background: ${theme.body.gray};
    border: 1px solid #86A4FF;
    border-radius: 8px;
    padding: 20px;
    font-size: ${theme.font.paragraph.fontSize};
    line-height: ${theme.font.paragraph.lineHeight};

    p:last-child {
      margin: 0;
    }
  }

  .ContentfulElementQuote {
    padding: 0 30px;

    .ContentfulElementQuote_text {
      font-size: ${theme.font.quote.fontSize};
      line-height: ${theme.font.quote.lineHeight};
      font-weight: 300;
      font-style: italic;
      color: ${theme.textColor.suvaGray};
      margin-bottom: 20px;
    }
    .ContentfulElementQuote_author {
      font-size: ${theme.font.paragraph.fontSize};
      line-height: ${theme.font.paragraph.lineHeight};
      font-weight: 800;
      color: ${theme.textColor.darkGrayText};

      .ContentfulElementQuote_author_name,
      .ContentfulElementQuote_company {
        display: inline;
      }
    }
    .ContentfulElementQuote_role {
      font-size: ${math(`${theme.font.paragraph.fontSize} * 0.7`)};
      color: ${theme.textColor.silverText};
    }
  }

  .ContentfulElementImageColumn {
    display: inline;
    width: 40%;
    float: left;
    margin: 10px 30px 40px 0;

    img {
      margin: 0;
    }
    &.right {
      float: right;
      margin: 10px 0 40px 30px;
    }
  }

  /****************************/
  /* Algolia
  /****************************/
  .aa-Item[aria-selected=true] {
    background-color: ${theme.background.lightGray} !important;
  }
  li.aa-Item {
    padding: 0;
  }

  /****************************/
  /* Gatsby image
  /****************************/
  .gatsby-image-wrapper {
    >:first-child {
      display: none !important;
    }

    [data-main-image],
    img {
      position: initial;
      height: initial;
    }
  }
  .gatsby-image-wrapper-constrained {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /****************************/
  /* MEDIA QUERIES
  /****************************/
  @media(max-width: 991px) {
    body {
      margin-top: 50px;
    }
    .mobile-text-center {
      text-align: center;
      h2 {
        text-align: center !important;
      }
    }
  }
  @media(max-width: 767px) {
    .section {
      padding: 35px 0;
    }
  }
`;

export default GlobalStyles;
export {
  CTA,
  CTAButton,
};
