import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const loading = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spin = styled.div`
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 0px auto;
  position: relative;
  width: 2em;
  height: 2em;
  box-shadow: inset 0 0 0 4px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 50%;

  :before {
    border-radius: 50%;
    position: absolute;
    content: '';
    width: 1.2em;
    height: 2.2em;
    border-radius: 2.2em 0 0 2.2em;
    top: -0.1em;
    left: -0.1em;
    background: ${theme.hover.hoverLight};
    -webkit-transform-origin: 1.1em 1.1em;
    transform-origin: 1.1em 1.1em;
    -webkit-animation: ${loading} 2s infinite ease 1.5s;
    animation: ${loading} 2s infinite ease 1.5s;
  }

  :after {
    border-radius: 50%;
    position: absolute;
    content: '';
    width: 1.2em;
    height: 2.2em;
    background: ${theme.hover.hoverLight};
    border-radius: 0 2.2em 2.2em 0;
    top: -0.1em;
    left: 0.9em;
    -webkit-transform-origin: 0.1em 1.1em;
    transform-origin: 0.1em 1.1em;
    -webkit-animation: ${loading} 2s infinite ease;
    animation: ${loading} 2s infinite ease;
  }
`;

export default Spin;
